import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { Season } from '@wbk/api';
import { Button } from '@wbk/ui';
import { useAnalytics } from '@wbk/analytics';

type Props = {
  season: Season;
} & React.HTMLAttributes<HTMLElement>;

const SeasonListItem = ({ season, ...props }: Props) => {
  const { t } = useTranslation();
  const { generateLeadEvent } = useAnalytics();

  return (
    <Button
      as={Link}
      href={`/season/${season.slug}`}
      className='relative flex aspect-1 h-full w-full flex-col justify-end gap-5 p-5 md:gap-10 md:p-10'
      style={{ background: season.background_color }}
      {...props}
      onClick={() => {
        generateLeadEvent({
          event_start_price: season.event_start_price,
          currency: season?.currency || 'SAR',
          slug: season.slug,
          title: season.title,
        });
      }}
    >
      <Image
        className='bg-secondary-dark absolute inset-0 h-full w-full object-cover object-center'
        src={season.poster}
        fill
        alt=''
      />
      <div className='relative self-start text-start'>
        <h3 className='md:mb-2 md:text-3xl' style={{ color: season.text_color }}>
          {season.title}
        </h3>
        <div
          className='prose hidden text-start md:inline'
          dangerouslySetInnerHTML={{ __html: season.informative_box || '' }}
        />
      </div>
      <Button
        className='relative w-full shrink-0 whitespace-nowrap md:py-3'
        arrow
        tabIndex={-1}
        style={{ background: season.action_color, color: season.background_color }}
      >
        {t('event:buy_tickets')}
      </Button>
    </Button>
  );
};

export default SeasonListItem;
