import Image from 'next/image';
import { APP_STORE_URL, GOOGLE_PLAY_STORE_URL, HUAWEI_STORE_URL } from '@/src/constants/apps';

const DownloadApp = () => {
  return (
    <div className='mt-2 flex items-center gap-4 text-secondary'>
      <a href={APP_STORE_URL} target='_blank' rel='noreferrer'>
        <Image
          src='/images/app/app-store.png'
          width={122}
          height={42}
          alt='Download on the app store'
        />
      </a>
      <a href={GOOGLE_PLAY_STORE_URL} target='_blank' rel='noreferrer'>
        <Image
          src='/images/app/google-play.png'
          width={137}
          height={42}
          alt='Get it on google play'
        />
      </a>
      <a href={HUAWEI_STORE_URL} target='_blank' rel='noreferrer'>
        <Image
          src='/images/app/huawei-app.png'
          width={137}
          height={42}
          alt='Explore it on huawei app gallery'
        />
      </a>
    </div>
  );
};

export default DownloadApp;
