import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import DownloadApp from '../profile/orders/DownloadApp';
import Reveal from '../animation/Reveal';

const DownloadApps = () => {
  const { t } = useTranslation();

  return (
    <section className='relative overflow-hidden bg-[#00173B]'>
      <div className='container flex flex-col items-center md:flex-row'>
        <div className='relative flex-1 md:py-20'>
          <Reveal
            as='h2'
            className='font-bold !leading-tight md:text-5xl'
            once={false}
            threshold={0.7}
          >
            {t('home:get_e_tickets')}
          </Reveal>
          <Reveal className='my-5' as='p' once={false} threshold={0.7} delay={0.2}>
            {t('home:e_tickets_description')}
          </Reveal>
          <Reveal once={false} threshold={0.7} delay={0.2}>
            <DownloadApp />
          </Reveal>
        </div>
        <div className='flex-1 self-end'>
          <Image src='/images/app/phones.png' width={800} height={800} alt='' />
        </div>
      </div>
    </section>
  );
};

export default DownloadApps;
